<template>
    <properties-component v-bind="$props" @close="$emit('close')">

        <b-form-group label-cols="3" :label="'Link|Lien'|tr" v-if="component.useBinding">
            <select-cms-item-field :item-type="block.itemType" v-model="component.linkField" field-type="any-link"/>
        </b-form-group>

        <b-form-group v-else :label="'Link|Lien'|tr">
            <input-link v-model="component.link"/>
        </b-form-group>

        <b-form-group label-cols="2">
            <template #label>
                <span v-tr>Style</span>
                <btn-responsive-prop v-model="component" field="buttonType"/>
            </template>
            <input-button-type v-model="component.mediaProxy.buttonType"/>
        </b-form-group>

        <b-form-group>
            <btn-responsive-prop v-model="component" field="borderRadius" no-label/>
            <input-border-radius v-model="component.mediaProxy.borderRadius"/>
        </b-form-group>

        <b-form-group label-cols="3">
            <template #label>
                <span v-tr>Size|Taille</span>
                <btn-responsive-prop v-model="component" field="size"/>
            </template>
            <b-select v-model="component.mediaProxy.size">
                <option value="xs" v-tr>Extra Small|Très petit</option>
                <option value="sm" v-tr>Small|Petit</option>
                <option value="md" v-tr>Normal</option>
                <option value="lg" v-tr>Large</option>
                <option value="xl" v-tr>Extra Large|Très large</option>
                <option value="xxl" v-tr>Ultra Large|Ultra large</option>
            </b-select>
        </b-form-group>

        <b-form-group :label="'Icons|Icônes'|tr">
            <b-row>
                <b-col>
                    <btn-responsive-prop v-model="component" field="leftIcon" style="left: -10px; top: 12px"/>
                    <input-glyph v-model="component.mediaProxy.leftIcon" placeholder=""/>
                </b-col>
                <b-col>
                    <btn-responsive-prop v-model="component" field="rightIcon" style="left: -10px; top: 12px"/>
                    <input-glyph v-model="component.mediaProxy.rightIcon" placeholder=""/>
                </b-col>
            </b-row>
        </b-form-group>

        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputButtonType from "@/components/editor/properties/inputs/input-button-type.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-component-button #hot-reload-debug
export default {
    name: `properties-component-button`,
    components: {BtnResponsiveProp, InputGlyph, InputButtonType, InputLink, SelectCmsItemField, InputBorderRadius, EInput, PropertiesComponent},
    extends: PropertiesComponent
}
</script>

<style lang="scss" scoped>
</style>
