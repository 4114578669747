<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-image title="Image" :value="block.image" v-bind="componentProps"/>
        <properties-component-text title="Label|Titre" :value="block.label" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";

// properties-block-list-category #hot-reload-debug
export default {
    name: `properties-block-list-category`,
    components: {InputBorderRadius, PropertiesComponentImage, PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
